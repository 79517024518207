import { PlusOutlined, RedoOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Divider,
  Drawer,
  PageHeader,
  Skeleton,
  Switch,
  message
} from "antd";
import { useEffect, useState } from "react";

import useCategoryStore from "stores/categoryStore";

import productApi from "utils/composable/productsApi";

import GoogleMap from "components/googleMap/GoogleMap";
import PendingSalesTable from "components/sales/PendingSalesTable";
import SalesForm from "components/sales/SalesForm";
import SalesTable from "components/sales/SalesTable";
import TaskForm from "components/task/TaskForm";
import useClientStore from "stores/clientStore";
import useSalesStore from "stores/salesStore";
import useTaskStore from "stores/taskStore";
import useWarehouseStore from "stores/warehouseStore";
import clientApi from "utils/composable/clientApi";
import salesApi from "utils/composable/salesApi";
import TaskApi from "utils/composable/taskApi";
import WareHouseApi from "utils/composable/warehouseApi";

import ClientApi from "utils/composable/clientApi";

const Sales = ({ currentUser }) => {
  const [submitLoader, setSubmitLoader] = useState(false);
  const categories = useCategoryStore((state) => state.categories);
  const [visible, setVisible] = useState(false);
  const [taskFormVisible, setTaskFormVisible] = useState(false);

  const [editData, setEditData] = useState("");
  const [tableLoading, setTableLoadings] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [activeTabKey1, setActiveTabKey1] = useState("tab1");
  const [saleTask, setsaleTask] = useState(true);

  const [locationDetails, setLocationDetails] = useState("");
  const [locationEditData, setLocationEditData] = useState(true);
  const [hideAddressBox, setHideAddressBox] = useState(true);
  const [submitLoading, setsubmitLoading] = useState(false);
  const [assignLoading, setAssignLoading] = useState(false);
  const [jobLoading, setJobLoading] = useState();
  const [saleData, setSaleData] = useState("");
  const [jobEditMode, setJobEditMode] = useState(false);
  const [jobAddMode, setJobAddMode] = useState(editData ? false : true);
  const [jobViewMode, setJobViewMode] = useState(false);
  const [vendorForm, setVendorForm] = useState(true);

  const [taskForm, setTaskForm] = useState(true);

  const { clientProducts } = ClientApi();

  const {
    updateTask,
    addTask,
    getTasks,
    getEngineers,
    editTaskJobs,
    AddTaskJobs,
  } = TaskApi();

  const tasks = useTaskStore((state) => state.tasks);

  //category store
  const { setCategories } = useCategoryStore((state) => ({
    setCategories: state.setCategories,
  }));
  const warehouses = useWarehouseStore((state) => state.warehouses);
  const { addProduct, getProducts, updateProducts } = productApi();
  const { getWarehouse } = WareHouseApi();

  //client Store
  const clients = useClientStore((state) => state.clients);

  const editSuccessful = (msg) => {
    message.success(msg);
  };

  const errorMsg = (msg) => {
    message.error(msg);
  };

  const sales = useSalesStore((state) => state.sales);

  const pendingSales = sales.filter(
    (sale) => sale.pending_delivery == true && !sale.task
  );

  const { addSale, getSales } = salesApi();
  const { getClients } = clientApi();

  useEffect(() => {
    !tasks.length && getTasks();

    setTableLoadings(true);
    getProducts().then((resp) => {
      setTableLoadings(false);
    });

    if (!clients.length) {
      getClients();
    }

    if (!sales.length) {
      getSales().then(() => {});
    }
  }, []);

  const submitHandler = (payload, form) => {
    console.log(payload, "sale payload");

    setSubmitLoader(true);

    try {
      addSale(payload)
        .then((msg) => {
          setSubmitLoader(false);
          setVisible(false);
          setTaskFormVisible(false);
          message.success(msg);
          form.resetFields();
        })
        .catch((err) => {
          console.log(err);
          message.error(err);
          setSubmitLoader(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const taskSubmitHandler = (payload, form) => {
    payload.jobs = payload.jobs.map((job) => {
      if (job.assigned) {
        return { ...job, status: "assigned" };
      } else {
        return { ...job };
      }
    });

    setsubmitLoading(true);

    addTask(payload)
      .then((msg) => {
        getSales();
        setsubmitLoading(false);
        setTaskFormVisible(false);
        setVisible(false);
        form.resetFields();
        message.success(msg);
        setLocationEditData(false);
        setLocationDetails(false);
      })
      .catch((msg) => {
        setsubmitLoading(false);
        errorMsg(msg);
      });
  };

  const editHandler = (template) => {
    setEditData(template);
    setVisible(true);
  };

  const confirmEditHandler = (updatePayload, form) => {
    setSubmitLoader(true);

    try {
      updateProducts(updatePayload)
        .then((msg) => {
          setSubmitLoader(false);
          setVisible(false);
          editSuccessful(msg);
          form.resetFields();
        })
        .catch((msg) => {
          console.log(msg);
          setSubmitLoader(false);
          errorMsg(msg);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const onClose = () => {
    setEditData("");
    setVisible(false);
    setTaskFormVisible(false);
  };

  const addNewSale = () => {
    setEditData(false);
    setTaskFormVisible(true);
  };

  const fillForm = () => {
    message.error("Please select Client and Date first ");
  };

  const ChangeLocation = (LocationEditData) => {
    setLocationEditData(LocationEditData?.location?.coordinates);
  };

  const getFieldEngineers = (client, date) => {
    if (!client || !date) {
      fillForm();
    } else {
      setAssignLoading(true);
      getEngineers(client, date)
        .then((resp) => {
         setAssignLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setAssignLoading(false);
          message.error(err);
        });
    }
  };

  const refresh = () => {
    setRefreshLoading(true);

    //    getProducts()
    // getSales().finally(() => {

    // })

    let pormise1 = getProducts();
    let promise2 = getSales();
    let promises = [pormise1, promise2];
    Promise.allSettled(promises).finally(() => {
      setRefreshLoading(false);
    });
  };

  const handleCancel = (form) => {
    setVisible(false);
  };

  const tabList = [
    {
      key: "tab1",
      tab: `Sales`,
    },
    {
      key: "tab2",
      tab: `Pending Deliveries ( ${pendingSales.length} ) `,
    },
  ];

  const onTab1Change = (key) => {
    setActiveTabKey1(key);
  };

  const creatSaleProductDeliveryJob = async (saleData) => {
   

    setEditData(false);
    setJobViewMode(false);

    clientProducts(saleData._id);

    const productList = pendingSales
      .filter((sale) => sale.client._id == saleData.client._id)
      .map((sale) => {
        sale.product = {};
        sale.product.clientProduct = sale?.client_product || null; // Earlier Code sale?.client_product || sale.product removed this because of recursive loop;
        sale.product.sale = sale._id;
        sale.product.name = sale.name;
        sale.product.barcode = sale.barcode;
        sale.product.qty = sale?.price?.qty || 1;

        return sale.product;
      });
 
  
   setSaleData(saleData);
    const jobs = productList.map((product) => {
      return {
        productId: product.clientProduct,
        status: "unassigned",
        type: "sold_product_delivery",
        description: "",
        product: product.clientProduct,
        company_product: saleData.company_product || null,
        sale: product.sale,
        qty: product.qty,
      };
    });

    const productListProp = productList.map((product) => {
      return {
        label: `${product?.name} - ${product?.barcode || ""}    ( ${
          product?.plan || "Warranty"
        } )`,
        value: product.clientProduct,
        key: product?.key,
      };
    });

    var tempServiceData = {};

    tempServiceData = {
      ...saleData,
      jobs: jobs,
      productList: productListProp,
    };

    setSaleData(tempServiceData);


    console.log("tempServiceData", tempServiceData)

    setJobAddMode(true);

    setVisible(true);
  };

  const showSaleTask = (task_id) => {
    setSaleData("");

    const editMode = (task) => {
      // task.formattedDate = formatDate(task?.due_date || "");

      task.jobs = task.jobs.map((job) => {
        return {
          ...job,
          productId: job.product?._id,
        };
      });

      // //reversing lat and lng position for google map
      // task.location.coordinates = task.location?.coordinates?.reverse();
      setEditData(task);
      setVisible(true);
    };

    editMode(tasks.find((task) => task._id == task_id));
    setJobViewMode(true);
  };

  const contentList = {
    tab1: (
      <Skeleton active loading={tableLoading}>
        <SalesTable
          currentUser={currentUser}
          showSaleTask={showSaleTask}
          editHandler={editHandler}
        />
      </Skeleton>
    ),

    tab2: (
      <Skeleton active loading={tableLoading}>
        <PendingSalesTable
          currentUser={currentUser}
          creatSaleProductDeliveryJob={creatSaleProductDeliveryJob}
          editHandler={editHandler}
          showSaleTask={showSaleTask}
        />
      </Skeleton>
    ),
  };

  const handleSwitchChange = () => {
    setVendorForm(!vendorForm);
  };

  const CustomTitle = ({ editData, switchValue, onSwitchChange }) => (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <span>{editData ? "Edit Sale Data" : "Create New Sale"}</span>
      <Switch
        checkedChildren="Vendor"
        unCheckedChildren="Stock"
        checked={switchValue}
        onChange={onSwitchChange}
      />
    </div>
  );

  return (
    <>
      <PageHeader
        className="site-page-header"
        style={{ padding: 0, marginTop: -15 }}
        title="Sales"
        extra={[
          <Button
            icon={<RedoOutlined />}
            loading={refreshLoading}
            onClick={refresh}
            key="1"
            type="dashed"
          ></Button>,
        ]}
      />
      <Divider orientation="center">
        <Button
          disabled={!currentUser.user.privileges.includes("product_management")}
          type="primary"
          onClick={addNewSale}
          icon={<PlusOutlined />}
        >
          New Sale
        </Button>
      </Divider>

      <Card
        style={{ width: "100%" }}
        tabList={tabList}
        activeTabKey={activeTabKey1}
        onTabChange={(key) => {
          onTab1Change(key);
        }}
      >
        {contentList[activeTabKey1]}
      </Card>

      <Drawer
        destroyOnClose={true}
        key={editData?._id}
        size={"large"}
        title={
          <CustomTitle
            editData={editData}
            switchValue={vendorForm}
            onSwitchChange={handleSwitchChange}
          />
        }
        placement="right"
        onClose={onClose}
        visible={taskFormVisible}
      >
        <SalesForm
          vendorForm={vendorForm}
          editData={editData}
          submitHandler={submitHandler}
          submitLoader={submitLoader}
          setVisible={setVisible}
          confirmEditHandler={confirmEditHandler}
        />
      </Drawer>

      <Drawer
        destroyOnClose={true}
        width={"100vw"}
        title={`Sale Task ${editData?.task_number || ""} `}
        size={"large"}
        placement="right"
        onClose={handleCancel}
        visible={visible}
      >

        <Divider style={{ padding: "1rem" }}>Enter Task Details</Divider>

        <TaskForm
          saleTask={saleTask}
      saleData={saleData}
        jobViewMode={jobViewMode}
          // *jobSubmitHandler={jobSubmitHandler}
          //* jobUpdateHandler={jobUpdateHandler}
          locationDetails={locationDetails}
          submitHandler={taskSubmitHandler}
          ChangeLocation={ChangeLocation}
          editData={editData}
          // *updateHandler={updateHandler}
          getFieldEngineers={getFieldEngineers}
            assignLoading={assignLoading}
          setJobLoading={setJobLoading}
         jobLoading={jobLoading}
          setJobEditMode={setJobEditMode}
         jobEditMode={jobEditMode}
          jobAddMode={jobAddMode}
          setJobAddMode={setJobAddMode}
        />

        <Divider style={{ marginBottom: "1rem" }}>Client Location</Divider>

        <GoogleMap
          taskForm={taskForm}
          hideAddressBox={hideAddressBox}
          locationEditData={locationEditData}
          setLocationDetails={setLocationDetails}
        />
        <Button
          className="submitButtonTask"
          form="taskForm"
          key="submit"
          type="primary"
          htmlType="submit"
          loading={submitLoading}
        >
          Submit
        </Button>
      </Drawer>
    </>
  );
};

export default Sales;
