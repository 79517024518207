import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Select,
  Skeleton,
  Space,
  Switch,
  Tooltip,
} from "antd";
import { useEffect, useRef, useState } from "react";
import clientAPi from "utils/composable/clientApi";
import ClientLocationApi from "utils/composable/clientLocationApi";

import TextArea from "antd/lib/input/TextArea";
import useClientLocationStore from "stores/clientLocationStore";
import useClientProductStore from "stores/clientProductStore";
import useClientStore from "stores/clientStore";
import useUserStore from "stores/teamStore";

import {
  EditOutlined,
  InfoCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import FieldEnggMap from "components/googleMap/FieldEnggMap";
import useTaskStore from "stores/taskStore";
import ClientApi from "utils/composable/clientApi";
import TeamApi from "utils/composable/teamApi";
import FieldEngineersList from "./FieldEngineersList";

import moment from "moment";
import humanize from "utils/formate-status";

import isArray from 'lodash/isArray';

import TaskContactForm from "./TaskContactForm";

const TaskForm = ({
jobViewMode,
jobUpdateHandler,
jobSubmitHandler,
submitHandler,
editData,
ChangeLocation,
updateHandler,
getFieldEngineers,
assignLoading,
setJobLoading,
jobLoading,
setJobEditMode,
jobEditMode,
jobAddMode,
setJobAddMode,
standByProductJob,
standbyProductChanged,
defectiveProductjob,
annualServiceTask,
annualServiceData,
saleData,
rentData,
returnTask,
standbyReturnJob,
standByProductReturnTaskData,
}) => {

  console.log("saleData", saleData)
const [form] = Form.useForm();
const clientLocations = useClientLocationStore(
  (state) => state.clientLocations
);
const users = useUserStore((state) => state.users);
const { clientProducts } = ClientApi();
const clients = useClientStore((state) => state.clients);
const { getClients } = clientAPi();
const { getUsers } = TeamApi();
const { getClientLocation } = ClientLocationApi();
const [selectedClient, setSelectedClient] = useState("");
const [contactList, setContactList] = useState([]);
// const [fieldEngineers, setFieldEngineers] = useState("");
const [currentContact, setCurrentContact] = useState("");
// const [showfieldEnggTable, setShowfieldEnggTable] = useState(false);
const [currentDate, setcurrentDate] = useState(
  moment(new Date()).format("YYYY-MM-DD")
);
const [visible, setVisible] = useState(false);
const [showJobs, setShowJobs] = useState(false);
const [allFieldEngineers, setAllFieldEngineers] = useState("");
const [allServiceWorkers, setAllServiceWorkers] = useState("");
const [allDeliveryWorkers, setAllDeliveryWorkers] = useState("");

const [fieldEngineer, setFieldEngineer] = useState(
  editData ? editData?.assigned : ""
);
const [activeId, setActiveId] = useState("");
const [otherFieldEngineers, setOtherFieldEngineers] = useState([]);
const [currentFormListkey, setCurrentFormListKey] = useState("");
const [onBoardEngineer, setOnBoardEngineer] = useState("");
const [onboarding, setOnboarding] = useState(false);
const [complaintJob, setComplaintJob] = useState(false);

const [cancelButtonCount, setCancelButtonCount] = useState(0);
const [billDelivery, setBillDelivery] = useState(false);
const [primaryClient, setPrimaryClient] = useState(false);
const [mainTaskType, setMaintaskType] = useState("");
const [selectedProduct, setSelectedProduct] = useState("");
const [maintenancetask, setMaintenanceTask] = useState("");
const [currentRole, setCurrentRole] = useState("onboard");
const [productsWithRent, setProductsWithRent] = useState([]);
const [currentClientProducts, setClientProducts] = useState([]);
const [unregistered_product, setUnregistered_product] = useState(false);
const [contactFormVisible, setContactFormVisible] = useState();

const [selectedProductSpecs, setSelectedProductSpecs] = useState({});
const [isSpecModalVisible, setIsSpecModalVisible] = useState(false);
const [activeKey, setActiveKey] = useState(null);

var currentfieldEngineersList = useTaskStore((state) => state.fieldEngineers);

const showModal = (key) => {
  setActiveKey(key); // Store the active key
  setIsSpecModalVisible(true);
};

const handleCancel = () => {
  setIsSpecModalVisible(false);
  setActiveKey(null); 
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
const renderInfoIcon = (fieldKey) => {
 
  
  if (selectedProductSpecs[fieldKey]) {
    return (
      <Tooltip title="View Product Specification">
        <InfoCircleOutlined onClick={() => showModal(fieldKey)}  style={{ color: '#1890ff', cursor: 'pointer', marginLeft: '8px'  }} />
      </Tooltip>
    );
  }
  return null;
  
};
const renderSpecifications = () => {
  if (!selectedProductSpecs[activeKey]) {
    return <p>No specification available.</p>;
  }

  return Object.entries(selectedProductSpecs[activeKey]).map(([key, value]) => {
    if (value !== null && value !== undefined && value !== '') {
      const displayValue = typeof value === 'boolean' ? (value ? 'Yes' : 'No') : value;
      const formattedKey = capitalizeFirstLetter(key.replace(/_/g, ' '));
      return (
        <p key={key}>
          <strong>{formattedKey}:</strong> {displayValue}
        </p>
      );
    }
    return null;
  });
  
};


const onFinish = (values) => {


  if (editData) {
    

    if (!showJobs) {
     

      if (isArray(fieldEngineer)) {
        if (fieldEngineer[0]?._id) {
          values.assigned = [fieldEngineer[0]?._id];
        } else {
          values.assigned = [];
        }
      } else {
        values.assigned = [fieldEngineer?._id || fieldEngineer];
      }

      // values.assigned = !isArray(fieldEngineer)
      //   ? [(fieldEngineer?._id || fieldEngineer)]
      //   : [];
    }

    values.due_date = values.due_date;
    values.task_id = editData._id;
    values.status = editData.status;
    console.log("values", values)
    console.log("form", form)

    updateHandler(values, form);
  } else {
    //setting submit payload

    if (!showJobs) {
      values.assigned = fieldEngineer ? [fieldEngineer] : [];
      values.jobs = [];

      if (annualServiceTask) values.type = "maintenance_service";
    } else {
      values.status = "unassigned";
      values.assigned = fieldEngineersInJobList.map(
        (list) => list.fieldEngineer
      );


      if (annualServiceTask) values.type = "maintenance_service";
      if (saleData || rentData) {
        values.type = "product_delivery";
      }
      //for rent return task
      if (returnTask) values.type = "product_return";

      //assigning field engineers in the list to jobs array

      values.jobs = values.jobs.map((job, key) => {
        if (productsWithRent.includes(job.productId)) {
          return {
            ...job,
            assigned:
              fieldEngineersInJobList.find(
                (list) => list.key == job?.FieldEngineerKey
              )?.fieldEngineer || null,
            product: job.productId,
            rent: currentClientProducts.find(
              (product) => product._id == job.productId
            ).rent,
          };
        } else {
          var finalValues = {
            ...job,
            assigned:
              fieldEngineersInJobList.find(
                (list) => list.key == job?.FieldEngineerKey
              )?.fieldEngineer || null,
            product: job.productId,
            // if product is not registered
            unregisteredProduct:
              unregistered_product === job.productId ? true : false,
          };

          // if(standByProductReturnTaskData)  {

          // delete finalValues.product

          // }

          return finalValues;
        }
      });

      console.log("values.jobs", values.jobs)
    }
  

    if (annualServiceTask || maintenancetask) {
      values.jobs = values.jobs.map((job, key) => {
        return {
          ...job,
          assigned: fieldEngineer ? fieldEngineer : null,

          product: job.productId,
        };
      });

      values.type = "maintenance_service";
    }
    if (standByProductReturnTaskData) {
      values.type = "stand_by_product_return";

    }

    // values.is_remote = false
    values.address = selectedClient.address;
    values.client = values.main_client;

    values.contact = {
      name: currentContact.name,

      phone: currentContact.phone,
    };
    if (currentContact.email) values.contact["email"] = currentContact.email;

    values.location_keywords = selectedClient.location_keywords;
    values.location = selectedClient.location;
    values.due_date = new Date(values.due_date);
    values.client_location = clientLocationId;


    if (unregistered_product) {
      values["unregistered"] = unregistered_product ? true : false;
    }


    submitHandler(values, form);
  }
};

const inputRef = useRef(null);

const AllClientProducts = useClientProductStore((state) => state.products);


var products;
if (
  form.getFieldValue("location_name") === "" ||
  form.getFieldValue("location_name") === "Primary Location"
) {
  products = AllClientProducts.filter((product) => !product.client_location);
} else {
  products = AllClientProducts;
}

const [clientList, setClientList] = useState([]);
const [clientLocationList, setClientLocationList] = useState([]);
const [productList, setProductList] = useState([]);
const [locationLoading, setLocationLoading] = useState(false);
const [fieldEngineersInJobList, setFieldEngineersInJobList] = useState([]);
const [jobArrayLength, setJobArraylength] = useState("");
//Field Engineer radio button value
const [value, setValue] = useState(editData ? editData?.assigned?._id : "");
const [clientLocationId, setClienttLocationId] = useState("");


const changeActiveMarker = () => {};

//adding fieldengineers in to job list
const addFieldEngineer = (fieldEngineer) => {
  const index = fieldEngineersInJobList.findIndex(
    (Fe) => Fe.key == currentFormListkey
  );



  if (index != -1) {

    fieldEngineersInJobList[index].fieldEngineer = fieldEngineer;
    fieldEngineersInJobList[index].name = users.find(
      (user) => user._id == fieldEngineer
    ).name;
  } else {

    setFieldEngineersInJobList(
      fieldEngineersInJobList.concat({
        fieldEngineer: fieldEngineer,
        key: currentFormListkey,
        name: users.find((user) => user._id == fieldEngineer).name,
      })
    );
  }
};

const increaseJobArrayLength = () => {
  setJobArraylength(jobArrayLength + 1);
};

const humanizeStatus = (status) => {

  var i,
    frags = status?.split("_");
  for (i = 0; i < frags?.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(" ");
};

const addInstallation = (jobData) => {
  editData.jobs[jobArrayLength]= {
    product: jobData.product,
    description: '',
    status: 'unassigned',
    type: 'installation',
    company_product: jobData.company_product,
    productId: jobData.productId,
    parentJob: jobData._id,
  };
  form.setFieldsValue({
    jobs:  editData.jobs,
  });
 
};

useEffect(() => {
  document.getElementById("lastDiv")?.scrollIntoView();

  if (editData) {
    if (editData?.assigned?.length) setOnBoardEngineer(true);

    if (editData.type == "bill_delivery") setBillDelivery(true);

    setMaintaskType(editData.type);

    ChangeLocation(editData);
    if (editData?.jobs?.length) {
      //to make fields length and  key value same even if cancel is clicked while creating job
      setJobArraylength(editData?.jobs?.length);
      setShowJobs(true);
     

    }
    setJobAddMode(false);
  } else {
    setJobArraylength(1);
    setJobAddMode(true);
  }
 
  //Setting form for Sale task
  if (saleData) {


    setMaintaskType("product_delivery");

    getClientLocation(saleData.client._id);

    const selectedClient = clients.find(
      (client) => client._id == saleData.client._id
    );

    setSelectedClient(selectedClient);
    setPrimaryClient(selectedClient);

 

    const contactList = selectedClient?.contacts?.map((contact) => {
      return {
        label: `${contact.name} , ${contact.phone} ,  ${contact.email}`,
        value: contact._id,
        key: contact._id,
      };
    });
    contactList?.length &&
      contactList?.push({ text: "Other", value: "Other", key: "other" });

    setContactList(contactList);

    clientProducts(saleData._id).then((msg) => {

 
      setProductList(saleData.productList);
    });



    setShowJobs(true);
    setJobAddMode(true);

    const clientLocationList = clientLocations
      .map((clientLocation) => {
        return {
          label: clientLocation.name,
          value: clientLocation._id,
          key: clientLocation._id,
        };
      })
      .push({
        label: "Primary Location",
        value: null,
        key: 1,
      });

    setClientLocationList(clientLocationList);
    setLocationLoading(true);

    form.setFieldsValue({
      main_client: saleData.client._id,
      contact: "",
      assigned: "",
      status: "unassigned",
      due_date: moment(new Date()).format("YYYY-MM-DD"),
      contacts: [""],
      jobs: saleData?.jobs || [""],
      address: "",
      type: "Product Delivery",
      description: "",
      is_remote: false,
      location_name: saleData?.client_location?.name || "Primary Location",
    });

    console.log("form", form)
  }

  //Setting form for Rent task

  if (rentData) {


    setMaintaskType("product_delivery");

    getClientLocation(rentData.client._id);

    const selectedClient = clients.find(
      (client) => client._id == rentData.client._id
    );

    setSelectedClient(selectedClient);
    setPrimaryClient(selectedClient);



    const contactList = selectedClient?.contacts?.map((contact) => {
      return {
        label: `${contact.name} , ${contact.phone} ,  ${contact.email}`,
        value: contact._id,
        key: contact._id,
      };
    });

    contactList?.length &&
      contactList?.push({ text: "Other", value: "Other", key: "other" });
    setContactList(contactList);

    clientProducts(rentData._id).then((msg) => {
 
      setProductList(rentData.productList);
    });



    setShowJobs(true);
    setJobAddMode(true);

    const clientLocationList = clientLocations
      .map((clientLocation) => {
        return {
          label: clientLocation.name,
          value: clientLocation._id,
          key: clientLocation._id,
        };
      })
      .push({
        label: "Primary Location",
        value: null,
        key: 1,
      });



    setClientLocationList(clientLocationList);
    setLocationLoading(true);

    form.setFieldsValue({
      main_client: rentData.client._id,
      contact: "",
      assigned: "",
      status: "Unassigned",
      due_date: moment(new Date()).format("YYYY-MM-DD"),
      contacts: [""],
      jobs: rentData?.jobs || [""],
      address: "",
      type: returnTask ? "Product Return" : "Product Delivery",
      description: "",
      is_remote: false,
      location_name: rentData.client_location?.name || "Primary Location",
    });
  }

  //Setting Form for StandBy return Task

  if (standByProductReturnTaskData) {


    setMaintaskType("Standby Product Return");

    getClientLocation(standByProductReturnTaskData.client._id);

    const selectedClient = clients.find(
      (client) => client._id == standByProductReturnTaskData.client._id
    );

    setSelectedClient(selectedClient);
    setPrimaryClient(selectedClient);


    const contactList = selectedClient?.contacts?.map((contact) => {
      return {
        label: `${contact.name} , ${contact.phone} ,  ${contact.email}`,
        value: contact._id,
        key: contact._id,
      };
    });

    contactList?.length &&
      contactList?.push({ text: "Other", value: "Other", key: "other" });
    setContactList(contactList);

    clientProducts(standByProductReturnTaskData.client._id).then((msg) => {

      setProductList(standByProductReturnTaskData.productList);
    });



    setShowJobs(true);
    setJobAddMode(true);

    const clientLocationList = clientLocations
      .map((clientLocation) => {
        return {
          label: clientLocation.name,
          value: clientLocation._id,
          key: clientLocation._id,
        };
      })
      .push({
        label: "Primary Location",
        value: null,
        key: 1,
      });



    setClientLocationList(clientLocationList);
    setLocationLoading(true);

    if (
      standByProductReturnTaskData.client_location &&
      clientLocations.length
    ) {


      setClienttLocationId(standByProductReturnTaskData.client_location._id);

      let newSelectedClient = clientLocations.find(
        (locations) =>
          locations._id == standByProductReturnTaskData.client_location._id
      );

      setSelectedClient(newSelectedClient);
    }

    form.setFieldsValue({
      main_client: standByProductReturnTaskData.client._id,
      contact: "",
      assigned: "",
      status: "Unassigned",
      due_date: moment(new Date()).format("YYYY-MM-DD"),
      contacts: [""],
      jobs: standByProductReturnTaskData?.job || [""],
      address: "",
      type: "stand_by_product_return",
      description: "",
      is_remote: false,
      location_name:
        standByProductReturnTaskData.client_location?.name ||
        "Primary Location",
    });
  }

  //maintenance_service
  if (annualServiceTask) {
    if (!annualServiceData.branch) {
      setMaintaskType("maintenance_service");

      getClientLocation(annualServiceData._id);


      const selectedClient = clients.find(
        (client) => client._id == annualServiceData._id
      );

      setSelectedClient(selectedClient);
      setPrimaryClient(selectedClient);



      const contactList = selectedClient?.contacts?.map((contact) => {
        return {
          label: `${contact.name} , ${contact.phone} ,  ${contact.email}`,
          value: contact._id,
          key: contact._id,
        };
      });

      contactList?.length &&
        contactList?.push({ text: "Other", value: "Other", key: "other" });
      setContactList(contactList);


      clientProducts(annualServiceData._id).then((msg) => {


        setProductList(annualServiceData.productList);
      });


      setShowJobs(true);
      setJobAddMode(true);

      const clientLocationList = clientLocations
        .map((clientLocation) => {
          return {
            label: clientLocation.name,
            value: clientLocation._id,
            key: clientLocation._id,
          };
        })
        .push({
          label: "Primary Location",
          value: null,
          key: 1,
        });


      setClientLocationList(clientLocationList);
      setLocationLoading(true);

      form.setFieldsValue({
        main_client: annualServiceData._id,
        contact: "",
        assigned: "",
        status: "Unassigned",
        due_date: moment(new Date()).format("YYYY-MM-DD"),
        contacts: [""],
        jobs: annualServiceData.jobs,
        address: "",
        type: "Maintenance Service",
        description: "",
        is_remote: false,
        location_name:
          annualServiceData.client_location?.name || "Primary Location",
      });
    } else {
      setSelectedClient(annualServiceData);

      setClienttLocationId(annualServiceData._id);

      setMaintaskType("maintenance_service");

      getClientLocation(annualServiceData.client);

      const selectedClient = clients.find(
        (client) => client._id == annualServiceData.client
      );

      // setSelectedClient(selectedClient);
      setPrimaryClient(selectedClient);



      const contactList = annualServiceData?.contacts?.map((contact) => {
        return {
          label: `${contact.name} , ${contact.phone} ,  ${contact.email}`,
          value: contact._id,
          key: contact._id,
        };
      });

      contactList?.length &&
        contactList?.push({ text: "Other", value: "Other", key: "other" });
      setContactList(contactList);



      clientProducts(annualServiceData.client, annualServiceData._id).then(
        (msg) => {


          const productList = msg.client_products
            .filter((product) => product.barcode != "0000")
            .map((product) => {
              return {
                label: `${product.name}  ${
                  product?.barcode || product.plan ? "-" : ""
                }  ${product?.barcode || ""}     ${
                  product.plan ? "(" + product?.plan + ")" || "" : ""
                } `,
                specifications: product?.specifications || null,
                value: product._id,
                key: product.key,
              };
            });
   

          setProductList(productList);
        }
      );

      // onClientLocationChange(annualServiceData._id, annualServiceData.client)



      setShowJobs(true);
      setJobAddMode(true);

      const clientLocationList = clientLocations
        .map((clientLocation) => {
          return {
            label: clientLocation.name,
            value: clientLocation._id,
            key: clientLocation._id,
          };
        })
        .push({
          label: "Primary Location",
          value: null,
          key: 1,
        });



      setClientLocationList(clientLocationList);
      setLocationLoading(true);

      form.setFieldsValue({
        main_client: annualServiceData.client,
        contact: "",
        assigned: "",
        status: "Unassigned",
        due_date: moment(new Date()).format("YYYY-MM-DD"),
        contacts: [""],
        jobs: annualServiceData.jobs,
        address: "",
        type: "Maintenance Service",
        description: "",
        is_remote: false,
        location_name: annualServiceData.name || "Primary Location",
      });
    }
  }

  const allFieldEngineers = users.filter((user) => user.role == "engineer");
  setAllFieldEngineers(allFieldEngineers);

  const allServiceWorkers = users.filter((user) => user.role == "service");
  setAllServiceWorkers(allServiceWorkers);

  const allDeliveryWorkers = users.filter(
    (user) =>
      user.role == "delivery" ||
      user.role == "service" ||
      user.role == "engineer"
  );
  setAllDeliveryWorkers(allDeliveryWorkers);

  //setting edit values
  console.log("editData", editData)
  if (editData) {
   
    const humanizeJobsStatus = (jobs) => {
      const updatedJobs = jobs.map((job) => {
        var i,
          frags = job.status.split("_");
        for (i = 0; i < frags.length; i++) {
          frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
        }
        job.status = frags.join(" ");
        return job;
      });

      return updatedJobs;
    };

    form.setFieldsValue({
      main_client: editData?.client?._id || "",
      contact: editData?.contact?.name || "",
      assigned: editData?.assigned?._id || "",
      status: humanizeStatus(editData?.status || "assigned"),
      due_date: editData
        ? editData?.formattedDate || moment(new Date()).format("YYYY-MM-DD")
        : moment(new Date()).format("YYYY-MM-DD"),
      contacts: editData?.contacts || [""],
      jobs: humanizeJobsStatus(editData?.jobs || [""]),

      address: editData?.address || "",
      type: editData?.type || "Inventory Check",
      description: editData?.description || "",
      is_remote: editData?.is_remote || false,
      location_name: editData?.client_location?.name || "Primary Location",
    });
  }

  // Usual flow
  if (
    !editData &&
    !annualServiceData &&
    !saleData &&
    !rentData &&
    !standByProductReturnTaskData
  ) {
    form.setFieldsValue({
      main_client: editData?.client?.name || "",
      contact: editData?.contact?.name || "",
      assigned: editData?.assigned?._id || "",
      status: editData?.status || "unassigned",
      due_date: editData
        ? editData.formattedDate
        : moment(new Date()).format("YYYY-MM-DD"),
      contacts: editData?.contacts || [""],
      jobs: editData?.jobs || [""],
      address: editData?.address || "",
      type: editData?.type || "inventory_check",
      description: editData?.description || "",
      is_remote: editData?.is_remote || false,
    });
  }

  //Creating options for client list
  const clientList = clients.map((client) => {
    return {
      label: ` ${client.name}   (${humanize(client.status)})`,
      value: client._id,
      key: client._id,
    };
  });

  //getting client Products when client changes

  if (editData) {
    clientProducts(editData?.client?._id).then((msg) => {
    
    });

    setSelectedClient(editData);
    setcurrentDate(
      editData?.formattedDate || moment(new Date()).format("YYYY-MM-DD")
    );


  }

  setClientList(clientList);

  setValue(editData ? editData?.assigned?._id : "");

  getUsers();
}, [editData]);



useEffect(() => {
  getClients();
  //Creating select options from client Locations

  const clientLocationList = clientLocations.map((clientLocation) => {
    return {
      label: clientLocation.name,
      value: clientLocation._id,
      key: clientLocation._id,
    };
  });

  clientLocationList.push({
    label: "Primary Location",
    value: "",
    key: 1,
  });

  setClientLocationList(clientLocationList);
  setLocationLoading(false);
}, [clientLocations]);

//useEffect for changing map location nd creating contact list
useEffect(() => {
  if (!editData) {
    setCurrentFormListKey(0);
    ChangeLocation(selectedClient);
  }

  //getting Client Products when client changes

  if (selectedClient && selectedClient.additional_locations) {
    clientProducts(selectedClient?._id).then((msg) => {

      setProductsWithRent(
        msg.client_products.map((product) => {
          if (product.rent) return product._id;
        })
      );
      setClientProducts(msg.client_products);
    });
  }

  const contactList = selectedClient?.contacts?.map((contact) => {
    return {
      label: `${contact.name} , ${contact.phone} ,  ${contact.email}`,
      value: contact._id,
      key: contact._id,
    };
  });

  contactList?.length &&
    contactList?.push({ text: "Other", value: "Other", key: "other" });
  setContactList(contactList);
}, [selectedClient]);

//Setting current role

useEffect(() => {
  if (editData?.type == "stand_by_product_return") {


    setCurrentRole("delivery");
  }

  if (editData?.type == "rent_product_delivery") {


    setCurrentRole("delivery");
  }

  if (defectiveProductjob) {
    setCurrentRole("delivery");

  } else if (standByProductJob) {
    setCurrentRole("delivery");

  } else if (rentData) {
    setCurrentRole("delivery");

  } else if (maintenancetask || annualServiceTask) {
    setCurrentRole("service");

  } else if (saleData) {
    setCurrentRole("delivery");

  } else if (billDelivery) {
    setCurrentRole("delivery");
 
  } else if (onboarding) {
    setCurrentRole("onboard");

  } else if (complaintJob) {
    setCurrentRole("engineer");
   
  } else if (standbyReturnJob) {
    setCurrentRole("delivery");
  } else {
    setCurrentRole("onboard");


  }
}, [
  billDelivery,
  saleData,
  maintenancetask,
  annualServiceTask,
  standByProductJob,
  defectiveProductjob,
  onboarding,
]);

const onClientChange = (clientId) => {
 
  setProductList([]);
  form.setFieldsValue({
    contact: "",
    location_name: "",
  });

  getClientLocation(clientId);
  setLocationLoading(true);
 
  //finding selected client
  const selectedClient = clients.find((client) => client._id == clientId);
 
  setSelectedClient(selectedClient);
  setPrimaryClient(selectedClient);
  setSelectedProduct(false);
};

const onClientLocationChange = (locationClientId, mainCLientId) => {
  var newSelectedClient = {};
  

  form.setFieldsValue({
    contact: "",
  });

  setClienttLocationId(locationClientId);

  if (locationClientId === "") {
    setSelectedClient(primaryClient);
  } else {
    if (annualServiceData?.branch || null) {
      newSelectedClient = annualServiceData;
      // newSelectedClient.plan.next_due_date = selectedClient?.plan?.next_due_date
    } else {
      newSelectedClient = clientLocations.find(
        (locations) => locations._id === locationClientId
      );

      //selecting  client from client Locations
   
      newSelectedClient.plan = {};
      newSelectedClient.plan.next_due_date =
        selectedClient.plan.next_due_date;
      setSelectedClient(newSelectedClient);
    }
  }

  clientProducts(primaryClient?._id || mainCLientId, locationClientId).then(
    () => {
     
    }
  );
};

const onClientContactChange = (contactId) => {
  if (contactId == "Other") {
    setContactFormVisible(true);
  } else {

    const currentContact =
      selectedClient?.contacts.find((contact) => contact._id == contactId) ||
      [];

    setCurrentContact(currentContact);
  }
};




const onTypeChange = (value) => {


  if (value == "inventory_check") {
    setOnboarding(true);
    setMaintenanceTask(false);
    setBillDelivery(false);
    setShowJobs(false);
  } else if (value == "bill_delivery") {
    setMaintenanceTask(false);
    setBillDelivery(true); //to remove isRemote Option
    setShowJobs(false);
    setOnboarding(false);
  } else {
    setBillDelivery(false);
    setShowJobs(true);
  }

  setMaintaskType(value);
  setSelectedProduct(false);

  if (value == "maintenance_service") {
    let jobs = products.map((product) => {
      return {
        assigned: null,
        productId: product._id,
        status: "unassigned",
        type: "maintenance_service",
        due_date: product.due_date,
        description: "",
      };
    });



    form.setFieldsValue({
      jobs: jobs,
    });

    setMaintenanceTask(true);
    setOnboarding(false);
    setComplaintJob(false);
  }

  if (value == "complaint") {
    setOnboarding(false);
    setComplaintJob(true);

    let jobs = [];


    form.setFieldsValue({
      jobs: jobs,
    });

    setMaintenanceTask(false);
  }
};

const jobEditHandler = (key) => {
  setJobEditMode(true);

  document.getElementById("row" + key).disabled = false;
  document.getElementById(key + "change").disabled = false;
  document.getElementsByClassName("row" + key).disabled = false;
  document.getElementById(key + "editSubmitButton").style.display = "block";
};

// const addNewJobHandler = () => {
//   let jobs = form.getFieldValue("jobs");

//   const key = jobs.length;

//   // document.getElementById(key +'jobSubmitButton').style.display='block'
// };

const changeFieldEngineer = (key) => {
  const jobs = form.getFieldValue("jobs");

  const FEChangingJob = jobs[key];


  setActiveId(FEChangingJob?.assigned || false);
};

//creating new product list when products changes in store

useEffect(() => {
  if (!annualServiceTask && editData) {
    const productList = products.map((product) => {
      return {
        label: `${product.name}  ${
          product?.barcode || product.plan ? "-" : ""
        }  ${product?.barcode || ""}     ${
          product.plan ? "(" + product?.plan + ")" || "" : ""
        } `,
        value: product._id,
        key: product.key,
        specifications: product?.specifications || null,
        disabled: product.status == "with_client" ? false : true,
      };
    });

    setProductList(productList);
  } else if (
    !annualServiceTask &&
    !editData &&
    !saleData &&
    !rentData &&
    !standByProductReturnTaskData
  ) {
    //Main Product List

    const productList = products.map((product) => {
 
      return {
        label: `${product.name}  ${
          product?.barcode || product.plan ? "-" : ""
        }  ${product?.barcode || ""}     ${
          product.plan ? "(" + product?.plan + ")" || "" : ""
        } `,
        value: product._id,
        key: product.key,
        dueDate: product.due_date,
        specifications: product?.specifications || null,
        disabled:
          product.status == "with_client"
            ? false
            : product.status == "rented_to_client"
            ? false
            : product.unregistered
            ? false
            : true,
      };
    });


    setProductList(productList);
  }

  if (mainTaskType == "maintenance_service" && !editData) {
  
    if (
      form.getFieldValue("location_name") == "" ||
      form.getFieldValue("location_name") == "Primary Location"
    ) {

  
      products = AllClientProducts.filter(
        (product) => !product.client_location && !product.unregistered
      );


      let jobs = products.map((product) => {
        return {
          productId: product._id,
          status: "unassigned",
          type: "maintenance_service",
          due_date: product.due_date,
          description: "",
        };
      });



      form.setFieldsValue({
        jobs: jobs,
      });
    } else {
    
      let jobs = products
        .filter((product) => !product.unregistered)
        .map((product) => {
          return {
            productId: product._id,
            status: "unassigned",
            type: "maintenance_service",
            due_date: product.due_date,
            description: "",
          };
        });
 
     

      form.setFieldsValue({
        jobs: jobs,
      });
    }
  }

}, [AllClientProducts]);

useEffect(() => {
  if (standByProductJob) {
    setJobAddMode(true);
  }

  return () => setJobEditMode(false);
}, []);

const [
  currentFormKeyForShowingProductDetails,
  setCurrentFormKeyForShowingProductDetails,
] = useState("");

//Checking if selected product is Unregistered or not
const jobProductChange = (productId, data, fieldKey) => {
  
  if(data?.specifications){
    setSelectedProductSpecs(prevState => ({
      ...prevState,
      [fieldKey]: data.specifications
    }));
  }else{
    setSelectedProductSpecs(prevState => ({
      ...prevState,
      [fieldKey]: null
    }));
  }


  const unregistered_product = currentClientProducts.find(
    (product) => product._id == productId && product.barcode == "0000"
  );

  if (unregistered_product) {


    setUnregistered_product(productId);
  } else {
    let disabledProductList = productList.map((prod) => {
      if (prod.value == productId) {
        prod.disabled = true;
        return prod;
      } else {
        return prod;
      }
    });
  
    setProductList(disabledProductList);
  }
};

useEffect(() => {
  document.getElementById("lastDiv")?.scrollIntoView();
}, []);

//Fuzzy filter
/*
const fuzzyFilter = (query, product) => {
  if (query !== "") {

    var get_bigrams, j, len, obj, relevance, results, string_similarity;

    get_bigrams = function (string) {
   
      var i, j, ref, s, v;
      s = string.toLowerCase();
      v = new Array(s.length - 1);
      for (i = j = 0, ref = v.length; j <= ref; i = j += 1) {
        v[i] = s.slice(i, i + 2);
      }
      return v;
    };

    string_similarity = function (str1, str2) {


      var hit_count, j, k, len, len1, pairs1, pairs2, union, x, y;
      if (str1.length > 0 && str2.length > 0) {
        pairs1 = get_bigrams(str1);
        pairs2 = get_bigrams(str2);
        union = pairs1.length + pairs2.length;
        hit_count = 0;
        for (j = 0, len = pairs1.length; j < len; j++) {
          x = pairs1[j];
          for (k = 0, len1 = pairs2.length; k < len1; k++) {
            y = pairs2[k];
            if (x === y) {
              hit_count++;
            }
          }
        }
        if (hit_count > 0) {
          return (2.0 * hit_count) / union;
        }
      }
      return 0.0;
    };

    if (query.length >= 2) {
      results = [];

      for (j = 0, len = clients.length; j < len; j++) {
        var client = clients[j];


        relevance = string_similarity(query, client?.name || "");

        obj = {
          ...client,
          relevance: relevance,
        };
        results.push(obj);
      }


      var sortedResults = results.filter((result) => result.relevance > 0.1);
      sortedResults = sortedResults
        .sort((a, b) => a.relevance - b.relevance)
        .reverse();


      //  clientList= ([...sortedResults])

      let clientList = sortedResults.map((client) => {
        return {
          label: `${client.name}`,
          value: client._id,
          key: client._id,
        };
      });

      setClientList(clientList);
    } else {
      let clientList = clients.map((client) => {
        return {
          label: `${client.name}`,
          value: client._id,
          key: client._id,
        };
      });

      setClientList(clientList);
    }
  }
};
*/

const alphabeticFilter = (query, clients, setClientList) => {
  const filteredClients = clients.filter((client) =>
    client.name.toLowerCase().includes(query.toLowerCase())
  );

  const clientList = filteredClients.map((client) => ({
    label: client.name,
    value: client._id,
    key: client._id,
  }));

  setClientList(clientList);
};

const productFuzzyFilter = (query) => {
  if (query !== "") {

    var get_bigrams, j, len, obj, relevance, results, string_similarity;

    get_bigrams = function (string) {

      var i, j, ref, s, v;
      s = string.toLowerCase();
      v = new Array(s.length - 1);
      for (i = j = 0, ref = v.length; j <= ref; i = j += 1) {
        v[i] = s.slice(i, i + 2);
      }
      return v;
    };

    string_similarity = function (str1, str2) {
   

      var hit_count, j, k, len, len1, pairs1, pairs2, union, x, y;
      if (str1.length > 0 && str2.length > 0) {
        pairs1 = get_bigrams(str1);
        pairs2 = get_bigrams(str2);
        union = pairs1.length + pairs2.length;
        hit_count = 0;
        for (j = 0, len = pairs1.length; j < len; j++) {
          x = pairs1[j];
          for (k = 0, len1 = pairs2.length; k < len1; k++) {
            y = pairs2[k];
            if (x === y) {
              hit_count++;
            }
          }
        }
        if (hit_count > 0) {
          return (2.0 * hit_count) / union;
        }
      }
      return 0.0;
    };
    if (query.length > 2) {
      results = [];

      for (j = 0, len = products.length; j < len; j++) {
        var product = products[j];

 
        relevance = string_similarity(
          query,
          `${product?.name} ${product?.barcode}` || ""
        );

        obj = {
          ...product,
          relevance: relevance,
        };
        results.push(obj);
      }


      var sortedResults = results.filter((result) => result.relevance > 0.1);
      sortedResults = sortedResults
        .sort((a, b) => a.relevance - b.relevance)
        .reverse();


      //  clientList= ([...sortedResults])

      const productList = sortedResults.map((product) => {
        return {
          label: `${product.name}  ${
            product?.barcode || product.plan ? "-" : ""
          }  ${product?.barcode || ""}     ${
            product.plan ? "(" + product?.plan + ")" || "" : ""
          } `,
          value: product._id,
          key: product.key,
          specifications: product?.specifications || null,
          dueDate: product.due_date,
          disabled: product.status == "with_client" ? false : true,
        };
      });

   

      setProductList(productList);
    }
  } else {
    const productList = products.map((product) => {
   
      return {
        label: `${product.name}  ${
          product?.barcode || product.plan ? "-" : ""
        }  ${product?.barcode || ""}     ${
          product.plan ? "(" + product?.plan + ")" || "" : ""
        } `,
        value: product._id,
        key: product.key,
        specifications: product?.specifications || null,
        dueDate: product.due_date,
        disabled: product.status == "with_client" ? false : true,
      };
    });
    setProductList(productList);
  }
};

const handleSubmitError = (values) => {


  form
    .validateFields()
    .then(() => {})
    .catch((error) => {
      let errorElement;


    

      if (error.errorFields[0]["name"][0] == "jobs") {
 

        errorElement = document.querySelector(
          `#row${error.errorFields[0]["name"][1] + 1}`
        );
      } else {
        errorElement = document.querySelector(
          `#taskForm_${error.errorFields[0]["name"]}`
        );
      }

    



      if (errorElement) {
        errorElement.scrollIntoView({ behavior: "smooth" });
      }
    });
};

//Contact form  Cancel

const onContactFormCancel = () => {
  setContactFormVisible(false);
};

const onContactFormSubmit = (values) => {

  setCurrentContact(values);

  form.setFieldsValue({
    contact: `${values.name}, ${values.phone}, ${values.email}`,
  });

  setContactFormVisible(false);
};



return (
  <div
    style={{
      flexWrap: "wrap",
      maxWidth: "100vw",
      margin: "0 auto",
      padding: "5%",
      overflow: "hidden",
    }}
  >
    
    <Form
      disabled={editData?.status == "cancelled"}
      form={form}
      key={editData?._id}
      id="taskForm"
      name="taskForm"
      onFinish={onFinish}
      onFinishFailed={handleSubmitError}
      autoComplete="off"
    >
      <div
        className="ClientDetails"
        style={{
          flexWrap: "wrap",
          width: "100%",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Space direction="vertical">
          <Form.Item
            labelCol={{ span: 24, offset: 0 }}
            style={{ width: "20rem", transition: ".5s" }}
            name="main_client"
            label="Client"
            rules={[{ required: true }]}
          >
            <Select
              disabled={
                editData ||
                annualServiceTask ||
                saleData ||
                rentData ||
                standByProductReturnTaskData
              }
              style={{ width: "100%", maxWidth: "100%" }}
              showSearch
              placeholder="Select a client"
              optionFilterProp="children"
              onChange={onClientChange}
              onSearch={(input) => {
                alphabeticFilter(input, clients, setClientList);
              }}
              options={clientList}
              filterOption={false} 
            ></Select>
          </Form.Item>

          <div style={{ color: "#FF9933", marginBottom: "-3rem" }}>
            {selectedClient?.plan?.next_due_date
              ? `Plan Due Date  : ${moment(
                  selectedClient.plan.next_due_date
                ).format("MMM. D, YYYY")}`
              : ""}
          </div>
        </Space>

        <Form.Item
          labelCol={{ span: 24, offset: 0 }}
          name="location_name"
          label="Client Location"
          style={{ width: "20rem", maxWidth: "100%" }}
        >
          <Select
            disabled={
              editData || !selectedClient || standByProductReturnTaskData
            }
            style={{ width: "20rem", maxWidth: "100%" }}
            loading={locationLoading}
            showSearch
            placeholder="Select client location"
            optionFilterProp="children"
            onChange={onClientLocationChange}
            // onSearch={onSearch}
            options={clientLocationList}
            filterOption={(input, clientLocationList) =>
              clientLocationList.label
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          ></Select>
        </Form.Item>

        <Form.Item
          style={{ maxWidth: "100%", width: "20rem" }}
          labelCol={{ span: 24, offset: 0 }}
          name="contact"
          label="Select Contact"
          rules={[{ required: true }]}
        >
          <Select
            disabled={editData || !selectedClient}
            style={{ width: "100%", maxWidth: "100%" }}
            placeholder="Select a contact"
            optionFilterProp="children"
            onChange={onClientContactChange}
            options={contactList}
          ></Select>
        </Form.Item>
      </div>

      <Space style={{ width: "100%" }} direction="vertical">
        <>
          <div
            className="ClientDetails2"
            style={{
              flexWrap: "wrap",
              width: "100%",
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Form.Item
              labelCol={{ span: 24, offset: 0 }}
              name="due_date"
              label="Date"
              style={{ width: "20rem", maxWidth: "100%" }}
              rules={[{ required: true }]}
              // initialValue={todayDate}
            >
              <Input
                 min={new Date()} 
                disabled={
                  editData?.status == "completed" ||
                  editData?.status == "completed" ||
                  !selectedClient
                }
                onChange={(e) => {
                  setcurrentDate(e.target.value);
                }}
                style={{ width: "100%" }}
                type={"date"}
              />
            </Form.Item>

            <Tooltip
              title={!selectedClient ? "Please Select Client First" : ""}
            >
              <Form.Item
                labelCol={{ span: 24, offset: 0 }}
                name="type"
                style={{
                  width: "20rem",
                  marginTop:
                    annualServiceTask || maintenancetask ? "2rem" : ".5rem",
                  marginRight: ".5rem",
                  transition: ".5s",
                }}
                label="Type"
                rules={[{ required: true }]}
              >
                <Select
                  style={{
                    width: "100%",
                    maxWidth: "100%",
                    marginRight: ".5rem",
                    marginTop: ".5rem",
                  }}
                  disabled={
                    !selectedClient ||
                    editData ||
                    annualServiceTask ||
                    saleData ||
                    rentData ||
                    standByProductReturnTaskData
                  }
                  placeholder="Select  task type"
                  optionFilterProp="children"
                  onChange={onTypeChange}
                >
                  <Select.Option value="complaint">Complaint </Select.Option>
                  <Select.Option value="inventory_check">
                    Inventory Check{" "}
                  </Select.Option>
                  <Select.Option value="bill_delivery">
                    Bill Delivery{" "}
                  </Select.Option>
                  <Select.Option
                    disabled={!productList?.length}
                    value="maintenance_service"
                  >
                    Maintenance Service
                  </Select.Option>

                  {editData && (
                    <>
                      <Select.Option value="product_delivery">
                        Product Delivery
                      </Select.Option>
                      <Select.Option value="product_return">
                        Product Return
                      </Select.Option>
                    </>
                  )}

                  {(standByProductReturnTaskData || editData) && (
                    <>
                      <Select.Option value="stand_by_product_return">
                        Standby Product Return
                      </Select.Option>
                    </>
                  )}
                </Select>
              </Form.Item>

              <Space
                style={{ marginTop: "-5rem" }}
                direction="vertical"
                size={0}
              >
                {selectedClient?.last_maintenance_date && (maintenancetask || annualServiceData) && (
                <span style={{ color: "#FF9933", marginTop: "-1.5rem" }}>
                  Last Maintenance : {moment(selectedClient.last_maintenance_date).format("MMM. D, YYYY")}
                </span>
              )}


                <span style={{ color: "#FF9933" }}>
                  {(maintenancetask || annualServiceData) &&
                    `Next Maintenance   : ${
                      moment(selectedClient?.next_maintenance_date).format(
                        "MMM. D, YYYY"
                      ) || ""
                    }`}
                </span>
              </Space>
            </Tooltip>

            <Form.Item
              style={{ marginLeft: "0rem", marginRight: "10rem" }}
              valuePropName={"checked"}
              name="is_remote"
              labelCol={{ span: 24, offset: 0 }}
              label="Is Remote"
              initialValue={false}
              hidden={annualServiceTask}
            >
              <Switch
                disabled={
                  editData?.status == "completed" ||
                  !selectedClient ||
                  mainTaskType !== "complaint"
                }
                style={{ width: 100 }}
                checkedChildren="Yes"
                unCheckedChildren="No"
              />
            </Form.Item>
          </div>

          <Form.Item
            labelCol={{ span: 24, offset: 0 }}
            name="description"
            style={{ width: "100%", maxWidth: "100%" }}
            label="Description"
          >
            <TextArea
              disabled={editData?.status == "completed" || !selectedClient}
              style={{ width: "100%", maxWidth: "100%" }}
            />
          </Form.Item>
        </>
      </Space>

      <div
        className="ClientDetails2"
        style={{
          flexWrap: "wrap",
          width: "100%",
          display: "flex",
          float: "left",
        }}
      >
        <Form.Item
          hidden={true}
          style={{ width: "20rem", maxWidth: "100%" }}
          labelCol={{ span: 24, offset: 0 }}
          name="status"
          label="Status"
        >
          <Select
            disabled={editData}
            style={{ width: "100%" }}
            placeholder="Select Status"
            optionFilterProp="children"
            // onSearch={onSearch}
          >
            {!editData?.status == "in_progress" && (
              <Select.Option value="assigned">Assigned</Select.Option>
            )}
          </Select>
        </Form.Item>
      </div>

      {!showJobs ? (
        <Divider>
          {" "}
          {!editData && !fieldEngineer && !editData?.assigned?.length ? (
            <Tooltip
              title={
                !selectedClient || !currentDate
                  ? "Please select Client and Date first"
                  : ""
              }
            >
              
              <Button
                disabled={!selectedClient || !currentDate}
                onClick={() => {
                  (selectedClient || editData) && setVisible(true);
                  getFieldEngineers(
                    selectedClient ? selectedClient : editData,
                    currentDate ? currentDate : editData?.formattedDate
                    
                  );
                }}
              >
               Assign Team Member
              </Button>
            </Tooltip>
          ) : (
            
            <Button
              onClick={() => {
                (selectedClient || editData) && setVisible(true);
                getFieldEngineers(
                  selectedClient ? selectedClient : editData,
                  currentDate ? currentDate : editData?.formattedDate
                  
                );

                setActiveId(editData?.assigned[0]?._id);
              }}
            >
              {onBoardEngineer
                ? ` Change Team Member -   ${
                    editData
                      ? editData?.assigned[0]?.name ||
                        users.find((user) => user._id == fieldEngineer)?.name
                      : users.find((user) => user._id == fieldEngineer).name
                  }`
                : "Assign Team Member"}
            </Button>
          )}
        </Divider>
      ) : (
        //alignItems:'center',alignContent:'center'
        <>
          <Divider>Jobs</Divider>

          <div
            style={{
              maxWidth: "100vw",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <>
            
              {
                //For maintenance task

                (annualServiceData || maintenancetask) && ( 
                  <Divider>
                     {" "}
                    {!editData &&
                    !fieldEngineer &&
                    !editData?.assigned?.length ? (
                      <Tooltip
                        title={
                          !selectedClient || !currentDate
                            ? "Please select Client and Date first"
                            : ""
                        }
                      >
                        
                        <Button
                          disabled={!selectedClient || !currentDate}
                          onClick={() => {
                            (selectedClient || editData) && setVisible(true);
                            getFieldEngineers(
                              selectedClient ? selectedClient : editData,
                              currentDate
                                ? currentDate
                                : editData?.formattedDate
                              
                            );
                          }}
                        > 
                          Assign Team Member
                        </Button>
                      </Tooltip>
                    ) : (
                      <Button
                        // hidden={!maintenancetask}
                        onClick={() => {
                          (selectedClient || editData) && setVisible(true);
                          getFieldEngineers(
                            selectedClient ? selectedClient : editData,
                            currentDate
                              ? currentDate
                              : editData?.formattedDate
                            
                          );

                          setActiveId(editData?.assigned[0]?._id);
                        }}
                      >
                        {onBoardEngineer ||
                        annualServiceData ||
                        maintenancetask
                          ? ` Change Team Member -   ${
                              editData
                                ? editData?.assigned[0]?.name ||
                                  users.find(
                                    (user) => user._id == fieldEngineer
                                  )?.name
                                : users.find(
                                    (user) => user._id == fieldEngineer
                                  ).name
                            }`
                          : "Assign Team Member"}
                      </Button>
                    )}
                  </Divider>
                )
              }

              <Form.List name="jobs">
             
                {(fields, { add, remove }) => (
                  <>
                  
                    { fields.map(({ key, name, ...restField }) => (
                      <>

     {editData && editData?.jobs[key]?.type == "installation" && (
<Form.Item
  style={{}}
  labelCol={{ span: 24, offset: 0 }}
  name={[name, "ParentJob"]}
  id={name + 1 + "ParentJobInputField"}
  initialValue={editData?.jobs[key].parentJob}
  hidden
  {...restField}
>
  <Input
    disabled
    value={editData?.jobs[key].parentJob}
    name={"ParentJob"}
    id={key + 1 + "ParentJobInputField"}
  />
</Form.Item>
)}
                        <Space direction="vertical">
                         
                          <div
                            style={{
                              flexWrap: "wrap",
                              width: "100%",
                              display: "flex",
                              alignContent: "center",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Space direction="vertical">
                            {renderInfoIcon(key)}
      <Modal
        title="Product Specification"
        visible={isSpecModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        {renderSpecifications()}
      </Modal>
                               <Form.Item
                                hidden={
                                  editData
                                    ? editData?.jobs[key]?.type ==
                                      "stand_by_product_return"
                                    : true && standbyReturnJob
                                }
                                style={{
                                  marginBottom:
                                    selectedProduct || annualServiceData
                                      ? "0rem"
                                      : "",
                                  width: "15rem",
                                }}
                                labelCol={{ span: 24, offset: 0 }}
                                {...restField}
                                name={[name, "productId"]}
                                label={
                                  (saleData && saleData.jobs?.[key]?.qty > 1) || 
                                  (editData && editData?.jobs?.[key]?.qty > 1)
                                    ? `Client Product (Qty: ${
                                        saleData?.jobs?.[key]?.qty || editData?.jobs?.[key]?.qty
                                      })`
                                    : 'Client Product'
                                }
                                
                                className={"row" + key + 1}
                                id={`row${key + 1}`}
                                rules={[
                                  {
                                    required: true,
                                    message: "Product is missing",
                                  },
                                ]}
                                
                              >
                                <Select
                                  hidden={
                                    editData
                                      ? editData?.jobs[key]?.type ==
                                        "stand_by_product_return"
                                      : true && standbyReturnJob
                                  }
                                  disabled={
                                    (jobAddMode &&
                                      editData &&
                                      !(key + 1 == jobArrayLength)) ||
                                    (editData && !jobAddMode) ||
                                    standByProductJob ||
                                    saleData ||
                                    rentData ||
                                    maintenancetask ||
                                    annualServiceTask ||
                                    standByProductReturnTaskData ||
                                    (editData?.jobs?.[key]?.type === "installation")
                                  }
                                  className={"row" + key + 1}
                                  id={`row${key + 1}`}
                                  showSearch
                                  placeholder="Select the product"
                                  optionFilterProp="children"
                                  onSearch={(input) => {
                                    productFuzzyFilter(input);
                                  }}
                                  onChange={(value, data) =>
                                      jobProductChange(value, data, key)
                                  }
                                  options={productList}
                                  filterOption={(input, productList) => {
                                    return true;
                                  }}
                                ></Select>
                                
                              
                              </Form.Item>
                             
                            
                              {!editData && (
                                <span
                                  hidden={
                                    !selectedProduct && !annualServiceData
                                  }
                                  style={{
                                    display: !annualServiceData
                                      ? currentFormKeyForShowingProductDetails !==
                                        key
                                        ? "none"
                                        : "block"
                                      : "block",
                                    color: "#FF9933",
                                    marginTop: "0rem",
                                  }}
                                >
                                  {selectedProduct
                                    ? `Product Plan Expires on  ${moment(
                                        selectedProduct?.due_date
                                      ).format("MMM. D, YYYY")}`
                                    : "Product Plan Expires on " +
                                      moment(
                                        annualServiceData?.jobs[key]
                                          ?.due_date ||
                                          saleData?.jobs[key]?.due_date ||
                                          rentData?.jobs[key]?.due_date
                                      ).format("MMM. D, YYYY")}
                                </span>
                              )}
                            </Space>

                            {(!returnTask ||
                              standByProductReturnTaskData) && (
                              <Form.Item
                                initialValue={
                                  editData
                                    ? editData?.jobs[key]
                                        ?.stand_by_product_request?.product
                                        ?.name
                                    : ""
                                }
                                style={{ width: "12rem", width: "15rem" }}
                                labelCol={{ span: 24, offset: 0 }}
                                name={[name, "standbyProduct"]}
                                {...restField}
                                v
                                hidden={
                                  editData
                                    ? editData?.jobs[key]?.type !==
                                        "stand_by_product_delivery" ||
                                      editData?.jobs[key]?.type !==
                                        "stand_by_product_return"
                                    : standByProductReturnTaskData
                                    ? false
                                    : true
                                }
                                label="Standby Product"
                              >
                                <Select
                                  defaultValue={`${
                                    editData?.standByProduct?.name
                                  } (${
                                    editData?.standByProduct?.barcode || ""
                                  })`}
                                  disabled={
                                    (jobAddMode &&
                                      editData &&
                                      !(key + 1 == jobArrayLength)) ||
                                    editData?.status == "completed" ||
                                    editData ||
                                    !jobEditMode
                                  }
                                  id={`row${key + 1}`}
                                  optionFilterProp="children"
                                ></Select>
                              </Form.Item>
                            )}

                            {(standbyReturnJob || editData) && (
                              <Form.Item
                                initialValue={
                                  editData
                                    ? editData?.jobs[key]
                                        ?.stand_by_product_request?.product
                                        ?.name
                                    : ""
                                }
                                style={{ width: "12rem", width: "15rem" }}
                                labelCol={{ span: 24, offset: 0 }}
                                name={[name, "standbyProduct"]}
                                {...restField}
                                v
                                hidden={
                                  editData
                                    ? editData?.jobs[key]?.type !==
                                      "stand_by_product_return"
                                    : true
                                }
                                label="Standby Product"
                              >
                                <Select
                                  defaultValue={`${
                                    editData?.standByProduct?.name
                                  } (${
                                    editData?.standByProduct?.barcode || ""
                                  })`}
                                  disabled={
                                    (jobAddMode &&
                                      editData &&
                                      !(key + 1 == jobArrayLength)) ||
                                    editData?.status == "completed" ||
                                    editData ||
                                    !jobEditMode
                                  }
                                  id={`row${key + 1}`}
                                  optionFilterProp="children"
                                ></Select>
                              </Form.Item>
                            )}

                            <Form.Item
                              style={{ width: "15rem" }}
                              labelCol={{ span: 24, offset: 0 }}
                              {...restField}
                              className={"row" + key + 1}
                              name={[name, "type"]}
                              label="Type"
                              rules={[{ required: true }]}
                              initialValue={mainTaskType}
                            >
                              <Select
                                disabled={true}
                                className={"row" + key + 1}
                                id={`row${key + 1}`}
                                placeholder="Select  task type"
                                optionFilterProp="children"

                                // onChange={onTypeChange}
                              >
                                <Select.Option value="complaint">
                                  Complaint{" "}
                                </Select.Option>
                                <Select.Option value="inventory_check">
                                  Inventory Check{" "}
                                </Select.Option>
                                <Select.Option value="product_delivery">
                                  Product Delivery{" "}
                                </Select.Option>
                                <Select.Option value="bill_delivery">
                                  Bill Delivery{" "}
                                </Select.Option>
                                <Select.Option value="stand_by_product_delivery">
                                  Standby Product Delivery{" "}
                                </Select.Option>
                                <Select.Option value="stand_by_product_return">
                                  Standby Product Return
                                </Select.Option>
                                <Select.Option value="sold_product_delivery">
                                  Sold Product delivery
                                </Select.Option>
                                <Select.Option value="maintenance_service">
                                  Maintenance Service
                                </Select.Option>
                                <Select.Option value="rent_product_delivery">
                                  Rent Product Delivery
                                </Select.Option>
                                <Select.Option value="rent_product_return">
                                  Rent Product Return
                                </Select.Option>
                                <Select.Option value="defective_product_pickup">
                                  Defective Product Pickup
                                </Select.Option>
                                <Select.Option value="installation">
                                  Installation
                                </Select.Option>
                
                              </Select>
                            </Form.Item>


                       <Form.Item
                              style={{}}
                              labelCol={{ span: 24, offset: 0 }}
                              name={[name, "FieldEngineerKey"]}
                              id={key + 1 + "FieldEngineerInputField"}
                              initialValue={key + 1}
                              hidden
                              {...restField}
                            >
                              <Input
                                disabled={
                                  (editData &&
                                    !(currentFormListkey == key + 1)) ||
                                  editData?.status == "completed"
                                }
                                ref={inputRef}
                                value={key + 1}
                                name={"FieldEngineerKey"}
                                id={key + 1 + "FieldEngineerInputField"}
                              />
                            </Form.Item>

                            <Form.Item
                              initialValue={"unassigned"}
                              style={{ width: "12rem" }}
                              labelCol={{ span: 24, offset: 0 }}
                              {...restField}
                              name={[name, "status"]}
                              hidden={
                                editData
                                  ? editData?.jobs[key]?.type ==
                                    "stand_by_product_delivery"
                                  : false
                              }
                              label="Status"
                              rules={[{ required: true }]}
                            >
                              <Select
                                disabled={
                                  (jobAddMode &&
                                    editData &&
                                    !(key + 1 == jobArrayLength)) ||
                                  editData?.status == "completed" ||
                                  !editData ||
                                  !jobEditMode ||
                                  !(currentFormListkey == key + 1)
                                }
                                id={`row${key + 1}`}
                                placeholder="Status"
                                optionFilterProp="children"
                              >
                                <Select.Option key={1} value="assigned">
                                  Assigned{" "}
                                </Select.Option>
                                <Select.Option key={1} value="unassigned">
                                  Unassigned{" "}
                                </Select.Option>
                                {editData && (
                                  <>
                                    <Select.Option value="cancelled">
                                      {" "}
                                      Cancelled{" "}
                                    </Select.Option>
                                  </>
                                )}
                              </Select>
                            </Form.Item>
                           
                            {!editData || jobAddMode ? (
                              <Tooltip
                                title={
                                  !selectedClient || !currentDate
                                    ? "Please select Client and Date first"
                                    : ""
                                }
                              >
                                
                                <Button
                                  id={key + 1 + "assign"}
                                  // id={`row${key + 1}`}
                                  hidden={
                                    annualServiceData || maintenancetask
                                  }
                                  style={{
                                    marginTop: "1rem",
                                    display:
                                      (jobAddMode &&
                                        !fieldEngineersInJobList.find(
                                          (list) => list.key == key + 1
                                        )?.fieldEngineer) ||
                                      false
                                        ? "block"
                                        : "none",
                                    width: "15rem",
                                  }}
                                  disabled={
                                    !selectedClient ||
                                    !currentDate ||
                                    !jobAddMode ||
                                    (editData && !(key + 1 == jobArrayLength))
                                  }
                                  onClick={() => {
                                    (selectedClient || editData) &&
                                      setVisible(true);
                                    getFieldEngineers(
                                      selectedClient
                                        ? selectedClient
                                        : editData,
                                      currentDate
                                        ? currentDate
                                        : editData?.formattedDate
                                      
                                    );

                                    setCurrentFormListKey(key + 1);
                                    setActiveId("");
                                  }}
                                >
                                 Assign Team Member
                                </Button>

                                <Button
                                  id={key + 1 + "change"}
                                  disabled={
                                    jobAddMode &&
                                    editData &&
                                    !(key + 1 == jobArrayLength)
                                  }
                                  style={{
                                    marginTop: "1rem",

                                    display:
                                      (jobAddMode &&
                                        !fieldEngineersInJobList.find(
                                          (list) => list.key == key + 1
                                        )?.fieldEngineer) ||
                                      false
                                        ? "none"
                                        : "block",

                                    minWidth: "16rem",
                                  }}
                                  onClick={() => {
                                    (selectedClient || editData) &&
                                      setVisible(true);

                                    setCurrentFormListKey(key + 1);
                                    setActiveId(() =>
                                      editData
                                        ? editData?.jobs[key]?.assigned
                                            ?._id ||
                                          fieldEngineersInJobList.find(
                                            (list) => list?.key == key + 1
                                          )?.fieldEngineer
                                        : fieldEngineersInJobList.find(
                                            (list) => list?.key == key + 1
                                          )?.fieldEngineer
                                    );

                                    getFieldEngineers(
                                      selectedClient
                                        ? selectedClient
                                        : editData,
                                      currentDate
                                        ? currentDate
                                        : editData?.formattedDate
                                      
                                    );
                                  }}
                                >
                                  {` Change Team Member -  ${
                                    fieldEngineersInJobList?.find(
                                      (list) => list?.key == key + 1
                                    )?.name
                                  }`}
                                </Button>
                              </Tooltip>
                            ) : (
                              <>
                                {editData?.jobs[key]?.assigned ? (
                                  <Button
                                    disabled={
                                      editData &&
                                      !(currentFormListkey == key + 1)
                                    }
                                    id={
                                      !editData && !showJobs
                                        ? "change"
                                        : key + 1 + "change"
                                    }
                                    style={{
                                      marginTop: ".8rem",

                                      display:
                                        key + 1 ==
                                          (jobArrayLength && jobAddMode) ||
                                        !editData?.jobs[key]?.assigned
                                          ? "none"
                                          : "block",
                                    }}
                                    onClick={() => {
                                      (selectedClient || editData) &&
                                        setVisible(true);
                                      getFieldEngineers(
                                        selectedClient
                                          ? selectedClient
                                          : editData,
                                        currentDate
                                          ? currentDate
                                          : editData?.formattedDate
                                        
                                      );

                                      changeFieldEngineer(key);
                                      setCurrentFormListKey(key + 1);
                                    }}
                                  >
                                    {`Change Team Member -    ${
                                      editData?.jobs[key]?.assigned &&
                                      users.find((fe) =>
                                        editData?.jobs[key]
                                          ? editData?.jobs[key]?.assigned ==
                                            fe?._id
                                          : editData?.jobs[
                                              editData?.jobs.length - 1
                                            ]?.assigned == fe?._id
                                      )?.name
                                    }`}
                                  </Button>
                                ) : (
                                  <Button
                                    disabled={
                                      editData &&
                                      !(currentFormListkey == key + 1)
                                    }
                                    id={
                                      !editData && !showJobs
                                        ? "change"
                                        : key + 1 + "change"
                                    }
                                    style={{
                                      marginTop: "1rem",
                                      width: "15rem",
                                      display:
                                        key + 1 ==
                                          (jobArrayLength && jobAddMode) ||
                                        editData?.jobs[key]?.assigned
                                          ? "none"
                                          : "block",
                                    }}
                                    onClick={() => {
                                      (selectedClient || editData) &&
                                        setVisible(true);
                                      getFieldEngineers(
                                        selectedClient
                                          ? selectedClient
                                          : editData,
                                        currentDate
                                          ? currentDate
                                          : editData?.formattedDate
                                        
                                      );

                                      changeFieldEngineer(key);
                                      setCurrentFormListKey(key + 1);
                                    }}
                                  >
                                    {`Assign Team Member`}
                                  </Button>
                                )}
                              </>
                            )}

                            {!editData ? (
                              <>
                                {((!editData && key !== 0) ||
                                  annualServiceTask ||
                                  saleData ||
                                  rentData ||
                                  maintenancetask) && (
                                  <MinusCircleOutlined
                                    style={{}}
                                    onClick={() => {
                                      remove(name);
                           
                                      fieldEngineersInJobList.splice(key, 1);

                                    

                                      // setJobAddMode(false);
                                      setJobArraylength(jobArrayLength - 1);

                                      // key = key - 1;
                                    }}
                                  />
                                )}
                              </>
                            ) : (
                              <>
                                <Space>
                                  <>
                                    <Button
                                      type="primary"
                                      loading={jobLoading}
                                      onClick={() => {
                                        jobSubmitHandler(
                                          key,
                                          form,
                                          fieldEngineer
                                        );
                                        setCurrentFormListKey(key + 1);
                                      }}
                                      id={key + "jobSubmitButton"}
                                      style={{
                                        marginTop: "1rem ",
                                        marginLeft: "3rem",
                                        display:
                                          key + 1 == jobArrayLength &&
                                          jobAddMode
                                            ? "block"
                                            : "none",
                                      }}
                                    >
                                      Save
                                    </Button>
                                    <Button
                                      onClick={() => {
                                     
                                        if (jobAddMode) remove(name);
                                        setCancelButtonCount(
                                          cancelButtonCount + 1
                                        );
                                        setJobAddMode(false);
                                      
                                        setCurrentFormListKey(key + 1);

                                        // if (standByProductJob) editData.jobs.pop();
                                      }}
                                      id={key + 1 + "jobSubmitButton"}
                                      style={{
                                        marginTop: "1rem",

                                        display:
                                          key + 1 == jobArrayLength &&
                                          jobAddMode
                                            ? "block"
                                            : "none",

                                        marginLeft: "3px",
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                  </>
                                </Space>

                                {!jobViewMode && (
                                  <EditOutlined
                                    hidden={
                                      jobEditMode ||
                                      jobAddMode ||
                                      (key + 1 == jobArrayLength &&
                                        jobAddMode) ||
                                      standByProductJob ||
                                      defectiveProductjob ||
                                      editData?.status == "completed" ||
                                      editData?.status == "cancelled" ||
                                      editData?.jobs[key]?.status ==
                                        "cancelled" ||
                                      editData?.jobs[key]?.status ==
                                        "completed"
                                    }
                                    onClick={() => {
                                      jobEditHandler(key + 1);
                                      setCurrentFormListKey(key + 1);
                                    }}
                                    style={{
                                      marginTop: "2rem",
                                      marginLeft: "-3rem",
                                    }}
                                  />
                                )}

                                <Button
                                  type="primary"
                                  loading={jobLoading}
                                  onClick={() => {
                                    jobUpdateHandler(
                                      key - cancelButtonCount,
                                      form
                                    );
                                    // setCurrentFormListKey('')
                                  }}
                                  id={key + 1 + "editSubmitButton"}
                                  style={{
                                    marginTop: "1rem",
                                    marginLeft: "-3rem",
                                    display:
                                      jobEditMode &&
                                      currentFormListkey == key + 1
                                        ? "block"
                                        : "none",
                                    // marginLeft: "7.5rem",
                                  }}
                                >
                                  Update
                                </Button>

                                <Button
                                  onClick={() => {
                                    setJobEditMode(false);
                                    setCurrentFormListKey();

                                    if (standByProductJob)
                                      editData.jobs.pop();
                                    fields.pop();
                                  }}
                                  id={key + 1 + "editSubmitButton"}
                                  style={{
                                    marginTop: "1rem",
                                    marginLeft: "-2rem",

                                    display:
                                      jobEditMode &&
                                      currentFormListkey == key + 1
                                        ? "block"
                                        : "none",
                                  }}
                                >
                                  Cancel
                                </Button>
                              </>
                            )}
                          </div>

                          <Form.Item
                            style={{
                              width: "100%",
                            }}
                            labelCol={{ span: 24, offset: 0 }}
                            name={[name, "description"]}
                            initialValue={""}
                            label="Descriptions"
                            {...restField}
                          >
                            <TextArea
                              disabled={
                                ((jobAddMode &&
                                  editData &&
                                  !(key + 1 == jobArrayLength)) ||
                                  editData?.status == "completed" ||
                                  !editData ||
                                  !jobEditMode ||
                                  !(currentFormListkey == key + 1)) &&
                                !jobAddMode
                              }
                              name="description"
                            />
                          </Form.Item>
                          {editData && editData?.jobs[key]?.installation_pending && (
                        <Button
                          hidden={annualServiceTask || maintenancetask}
                          disabled={
                            jobEditMode ||
                            (jobAddMode && editData) ||
                            editData?.status == "completed" ||
                            editData?.status == "cancelled" ||
                            saleData ||
                            rentData
                            //  ||
                          }
                          style={{
                            width: "15rem",
                          }}
                          type="secondary"
                          onClick={() => {
                            increaseJobArrayLength();
                            addInstallation(editData.jobs[key]);
                            setJobAddMode(true);
                            setFieldEngineer(null);
                          }}
                          block
                          icon={<PlusOutlined />}
                        >
                          Create Installation Job
                        </Button>
                      )}
                        </Space>
                        

                        <Divider></Divider>
                      </>
                    ))}

                    <div
                      className="ClientDetails2"
                      style={{
                        flexWrap: "wrap",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                      }}
                    >
                      {!jobViewMode  && (
                        <Button
                          hidden={annualServiceTask || maintenancetask || (mainTaskType === "maintenance_service" && editData)}
                          disabled={
                            jobEditMode ||
                            (jobAddMode && editData) ||
                            editData?.status == "completed" ||
                            editData?.status == "cancelled" ||
                            saleData ||
                            rentData
                            //  ||
                          }
                          style={{
                            width: "20rem",
                          }}
                          type="primary"
                          onClick={() => {
                            add();
                            increaseJobArrayLength();
                            setJobAddMode(true);
                            setFieldEngineer(null);
                            
                          }}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add New Job
                        </Button>
                      )}
                    </div>
                    <div id="lastDiv"></div>
                  </>
                )}
              </Form.List>
            </>
          </div>
        </>
      )}

 

      <Modal
        title={
          defectiveProductjob
            ? "Assign for Delivery Job"
            : standByProductJob
            ? "Assign for Delivery Job"
            : rentData
            ? "Assign for Delivery Job"
            : maintenancetask
            ? "Assign for Maintenance Job"
            : saleData
            ? "Assign for Delivery Job"
            : billDelivery
            ? "Assign for Delivery Job"
            : annualServiceTask
            ? "Assign for Maintenance Job"
            : "Assign Team Member"
        }
        centered
        visible={visible}
        onOk={() => {
          setVisible(false);

          if (!fieldEngineer) return;

          if (showJobs) addFieldEngineer(fieldEngineer);

          if (jobEditMode) {
            const jobs = form.getFieldValue("jobs");

     
            jobs[currentFormListkey - cancelButtonCount - 1].assigned =
              fieldEngineer;

            editData.jobs[
              currentFormListkey - cancelButtonCount - 1
            ].assigned = fieldEngineer;

           
          }

          if (!showJobs) {
            setJobEditMode(true);
            setOnBoardEngineer(fieldEngineer);
           
            // editData && editData.assigned.push(fieldEngineer);
            editData && editData?.assigned?.pop();
            editData && editData?.assigned?.push(fieldEngineer);
          }
        }}
        onCancel={() => {
          !editData && setOnBoardEngineer(false);
          setJobEditMode(false);
          setVisible(false);
      
          setActiveId(false);
          setFieldEngineer(null);
          setOtherFieldEngineers([]);
          // setFieldEngineersInJobList([])
          // setAllFieldEngineers([])
          // setJobAddMode(false);
        }}
        width={1300}
      >
        <Skeleton loading={assignLoading} active>
          {/* <FieldEngineerTable></FieldEngineerTable> */}

          <div
            style={{ display: "flex", justifyContent: "space-evenly" }}
            size={20}
          >
            <FieldEnggMap
              currentRole={currentRole}
              setOtherFieldEngineers={setOtherFieldEngineers}
              allFieldEngineers={allFieldEngineers}
              allDeliveryWorkers={allDeliveryWorkers}
              allServiceWorkers={allServiceWorkers}
              mainTaskType={mainTaskType}
              setValue={setValue}
              selectedClient={selectedClient ? selectedClient : editData}
              setActiveId={setActiveId}
              changeActiveMarker={changeActiveMarker}
              activeId={activeId}
            ></FieldEnggMap>

            <FieldEngineersList
              currentRole={currentRole}
              otherFieldEngineers={otherFieldEngineers}
              allFieldEngineers={allFieldEngineers}
              value={value}
              setValue={setValue}
              setFieldEngineer={setFieldEngineer}
              editData={editData}
              activeId={activeId}
              changeActiveMarker={changeActiveMarker}
              setActiveId={setActiveId}
            ></FieldEngineersList>
          </div>
        </Skeleton>
      </Modal>
    </Form>

    <TaskContactForm
      visible={contactFormVisible}
      onCancel={onContactFormCancel}
      onSubmit={onContactFormSubmit}
    />
  </div>
);
};

export default TaskForm;
