import {
  CheckOutlined,
  InfoCircleOutlined,
  SearchOutlined
} from "@ant-design/icons";
import { Button, Divider, Input, Modal, Space, Table, Tooltip } from "antd";
import { useEffect, useState } from "react";
import useStandByReqStore from "stores/standByReqStore";
import useWarehouseStore from "stores/warehouseStore";
import WareHouseApi from "utils/composable/warehouseApi";

const WareHouseTable = ({
  warehouseId,
  receivedStandByitem,
  receiveProductToWareHouse,
  warehouseProducts,
  editMode,
  receiveButtonLoading,
  tableloading,
  currentUser
}) => {
  const { getWarehouseStock, closeStandByrequest } = WareHouseApi();


  const request = useStandByReqStore((state) => state?.standByReqs);
  const [currentReceiveButton, setCurrentReceiveButton] = useState();
  const currentWarehouseRequestNeedToBeReceived = request.filter(
    (request) =>
      request?.product?.warehouse._id == warehouseId &&
      request?.status == "returned"
  );



  var currentWarehouseStock = useWarehouseStore(
    (state) => state.warehouse_stock
  );



  const productsToBeReceived = currentWarehouseStock.filter((product) => product.status == "to_warehouse")
  const productsAlreadyInWarehouse = currentWarehouseStock.filter((product) => product.status !== "to_warehouse")
currentWarehouseStock=[...productsToBeReceived,...productsAlreadyInWarehouse]


//Status formating

  function humanizeStatus(str) {
    return str
      .replace(/^[\s_]+|[\s_]+$/g, '')
      .replace(/[_\s]+/g, ' ')
      .replace(/^[a-z]/, function (m) { return m.toUpperCase(); });
  }



  useEffect(() => {

    // if (!currentWarehouseStock.length) getWarehouseStock(warehouseId);

    return () => {
      currentWarehouseStock = [];
    };
  }, [warehouseId]);

  const showMoreData = (warehouse) => {
    Modal.info({
      title: "More",
      width: "800px",
      content: (
        <div>
          <Divider>Description</Divider>
          {warehouse.description}
          <Divider>Managers</Divider>

          {warehouse.managers.map((manager) => (
            <>
              <Space size={15}>
                <span style={{ fontWeight: "bolder" }}> {manager.name}</span>:{" "}
                <span style={{ float: "left" }}> </span> {manager.phone},{" "}
                {manager.email}
                <br></br>
              </Space>
              <br></br>
            </>
          ))}
        </div>
      ),
      onOk() {},
    });
  };

  const showSpecs = (specifications) => {
 
    Modal.info({
      title: "Specifications",
      content: (
        <div>
          {Object.keys(specifications).map((key, i) => (
            <p key={i}>
              <span style={{ fontWeight: "bolder" }}>{key + ":"}</span>
              {""}
              <span style={{  }}>
                {" " + specifications[key]}
              </span>
            </p>
          ))}
        </div>
      ),
      onOk() {},
    });
  };

  const warehouses = useWarehouseStore((state) => state.warehouses);







  //Fuzzy filter

  const [filteredData, setFilteredData] = useState(false)
  const [currentQuery, setCurrentQuery] = useState('')

  const fuzzyFilter = (query, product) => {


    setCurrentQuery(query)

    var get_bigrams, j, len, obj, relevance, results, string_similarity;

    get_bigrams = function (string) {
     
      var i, j, ref, s, v;
      s = string.toLowerCase();
      v = new Array(s.length - 1);
      for (i = j = 0, ref = v.length; j <= ref; i = j += 1) {
        v[i] = s.slice(i, i + 2);
      }
      return v;
    };


    string_similarity = function (str1, str2) {

  

      var hit_count, j, k, len, len1, pairs1, pairs2, union, x, y;
      if (str1.length > 0 && str2.length > 0) {
        pairs1 = get_bigrams(str1);
        pairs2 = get_bigrams(str2);
        union = pairs1.length + pairs2.length;
        hit_count = 0;
        for (j = 0, len = pairs1.length; j < len; j++) {
          x = pairs1[j];
          for (k = 0, len1 = pairs2.length; k < len1; k++) {
            y = pairs2[k];
            if (x === y) {
              hit_count++;
            }
          }
        }
        if (hit_count > 0) {
          return (2.0 * hit_count) / union;
        }
      }
      return 0.0;
    };


    if (query.length > 2) {
    results = [];

    for (j = 0, len = currentWarehouseStock.length; j < len; j++) {
      var rent = currentWarehouseStock[j];

      if (product) {
        relevance = string_similarity(query, rent?.name || "");
      } else {

        relevance = string_similarity(query, rent?.client?.name || "");
      }

      obj = {
        ...rent,
        relevance: relevance
      };
      results.push(obj);
    }


    var sortedResults = results.filter((result) => result.relevance > 0.2)
    sortedResults = sortedResults.sort((a, b) => a.relevance - b.relevance).reverse()

    rent = [...sortedResults]
    setFilteredData(sortedResults)

  }
  }




  useEffect(() => {

    if (filteredData.length) {

      fuzzyFilter(currentQuery)

    }


  }, [warehouseProducts])


  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "1",
      render: (_, record) => record.name,

      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {



        return (
          <>
            <Input
              style={{ margin: "1rem", width: "25rem" }}
              autoFocus
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                // confirm();

                fuzzyFilter(selectedKeys[0], true)
              }}
              onBlur={() => {

                fuzzyFilter(selectedKeys[0], true);
              }}
              placeholder="Search"
            ></Input>
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                fuzzyFilter(selectedKeys[0], true);
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });

                setFilteredData(false)
                setSelectedKeys("");
                confirm()
              }}
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {
        return record.name.replace(/[^a-zA-Z]/g, "").toLowerCase().includes(value.replace(/[^a-zA-Z]/g, "").toLowerCase());
      },
    },
    {
      title: "Barcode",
      dataIndex: "barcode",
      key: "2",
      render: (_, record) => record?.barcode,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              style={{ margin: "1rem", width: "25rem" }}
              autoFocus
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target?.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              placeholder="Search"
            ></Input>
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                confirm();
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });
                setSelectedKeys("");
                confirm();
              }}
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {
        

        return record?.barcode?.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      title: "Client",
      dataIndex: "client",
      key: "1",
      render: (_, record) =>
        (record.status == "with_client_on_stand_by" || record.status == "to_client" || record.status == "rented") && record?.client.name,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {



        return (
          <>
            <Input
              style={{ margin: "1rem", width: "25rem" }}
              autoFocus
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                // confirm();

                fuzzyFilter(selectedKeys[0])
              }}
              onBlur={() => {

                fuzzyFilter(selectedKeys[0]);
              }}
              placeholder="Search"
            ></Input>
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                fuzzyFilter(selectedKeys[0]);
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });

                setFilteredData(false)
                setSelectedKeys("");
                confirm()
              }}
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {
        return record.name.replace(/[^a-zA-Z]/g, "").toLowerCase().includes(value.replace(/[^a-zA-Z]/g, "").toLowerCase());
      },
    },

    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      render: (_, record) => record?.brand,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              style={{ margin: "1rem", width: "25rem" }}
              autoFocus
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target?.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              placeholder="Search"
            ></Input>
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                confirm();
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });
                setSelectedKeys("");
                confirm();
              }}
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {
      

        return record?.brand.toLowerCase().includes(value.toLowerCase());
      },
    },

    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (record) => record?.name,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              style={{ margin: "1rem", width: "25rem" }}
              autoFocus
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target?.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              placeholder="Search"
            ></Input>
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                confirm();
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });
                setSelectedKeys("");
                confirm();
              }}
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {
  

        return record?.category?.name
          .toLowerCase()
          .includes(value.toLowerCase());
      },
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) =>

        humanizeStatus(record.status) ,

      filters: [
        { text: "With Client ", value: "with_client_on_stand_by" },
        { text: "In stock products", value: "in_stock" },
        { text: "To Warehouse", value: "to_warehouse" },
        { text: "Moved to Bin", value: "moved-to-bin" },
        { text: "Rented", value: "rented" }
      ],

      onFilter: (value, record) => record.status.includes(value),
    },

     {
      title: "Use",
      dataIndex: "use",
      key: "4",
      render: (_, record) =>

        humanizeStatus(record.use) ,

       filters: [
         { text: "Rent ", value: "rent" },
         { text: "Sale", value: "sale" },
         { text: "Standy By ", value: "stand-by" },
       ],

       onFilter: (value, record) => record.use.includes(value),

   
    },
     {
      title: "Avl Qty",
      dataIndex: "qty",
      key: "5",
      render: (text, record) => (record.qty_based ? text : "NA"),
    },

    {
      title: "Details",
      dataIndex: "",
      key: "8",
      render: (record) =>
        record.specifications ? (
          <InfoCircleOutlined onClick={() => showSpecs(record.specifications)} />
        ) : null,
      align: "center",
      width: "5%",
    },

    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (product) =>
        product.status == "to_warehouse" && (


          <Tooltip
            title={"Receive Product"}
          >
          <Button
              hidden={!currentUser.user.privileges.includes("warehouse_management")}            
        icon={<CheckOutlined/>}
            loading={
              product._id == currentReceiveButton && receiveButtonLoading
            }
            onClick={() => {
              setCurrentReceiveButton(product._id);

              receiveProductToWareHouse(product._id,product.category); 

              var request = currentWarehouseRequestNeedToBeReceived.find(
                (req) => req.product._id == product._id
              );

              request && receivedStandByitem(request._id);
            }}
          >
            {" "}
    
          </Button>
          </Tooltip>
        ),
    },
  ];

  return (
    <>
      <Table
        loading={tableloading}
        rowClassName={"rowFontColor"}
        columns={columns}

        dataSource={filteredData ? filteredData : currentWarehouseStock}
        pagination={false}
        rowKey={"_id"}
      />
    </>
  );
};

export default WareHouseTable;
